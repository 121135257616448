import Dialog from '@/components/Dialog.vue';
import dialogs from '@/mixins/dialogs';
export default {
  mixins: [dialogs],
  methods: {
    async back(message) {
      console.log('message', message);
      const result = await this.$confirm({
        component: Dialog,
        props: {
          message:
            (message ? message : 'メニュー[画面:がめん]') +
            'に[戻:もど]ります。よろしいですか？',
          options: [
            { text: 'はい', value: true },
            { text: 'いいえ', value: false }
          ]
        }
      });
      if (result) {
        const dirs = this.$route.fullPath.split('/');
        dirs.pop();
        const path = dirs.length > 1 ? dirs.join('/') : '/';
        this.$router.push({ path: path }).catch(() => {});
      }
    }
  }
};
