<template>
  <div class="landmark">
    <!-- error -->
    <nav class="console">
      <div v-if="debug" class="current-state">{{ current.name }}</div>
      <a v-if="debug" @click="setState(state < STATE.DL ? state + 1 : 0)">
        <span> Next </span>
      </a>
      <a v-if="debug" :class="debug ? null : 'bad'" @click="debug = !debug">
        <span> Debug </span>
      </a>
    </nav>
    <nav class="btn-back" @click.stop="back()">
      <a href="#"><span class="sp-none">もどる</span></a>
    </nav>
    <nav
      :class="[
        'btn-auto-read',
        doSpeech ? 'active' : null,
        canSpeech ? null : 'disabled'
      ]"
      @click="toggleSpeech"
    >
      <a href="#">
        <span
          ><ruby><rb>読</rb><rt>よ</rt></ruby
          >み<ruby><rb>上</rb><rt>あ</rt></ruby
          >げ</span
        ></a
      >
    </nav>
    <nav class="bottom-console">
      <div>
        <a
          v-if="state === STATE.LOCATION_FOUND"
          key="bb-to-shoot"
          class="shoot"
          @click="setState(STATE.SHOOT)"
        >
          <span>
            <ruby><rb>撮影</rb><rt>さつえい</rt></ruby
            >する
          </span>
        </a>
        <a
          v-if="state === STATE.SHOOT"
          key="bb-shoot"
          class="shoot"
          @click="shoot"
        >
          <span> Shoot </span>
        </a>
        <a
          v-show="state === STATE.DL"
          ref="dl"
          key="bb-dl"
          class="dl"
          target="_blank"
        >
          <span>
            <ruby><rb>写真</rb><rt>しゃしん</rt></ruby
            >をダウンロードする
          </span>
        </a>
        <a
          v-show="state === STATE.DL"
          key="bb-back"
          href="/ability/"
          class="back"
        >
          <span> メニューにもどる </span>
        </a>
        <a
          v-if="state === STATE.SHOOT"
          key="bb-switch"
          class="shoot"
          @click="switchCamera"
        >
          <span>
            カメラ<ruby><rb>切替</rb><rt>きりかえ</rt></ruby>
          </span>
        </a>
      </div>
    </nav>
    <main>
      <!-- todo -->
      <div :class="['todo', 'todo-' + current.id]">
        <img
          class="todo-image"
          :src="current.todo.image"
          @click="debug_command"
        />
        <div class="todo-message">
          <div class="todo-title">
            <span
              ref="todoTitle"
              :style="'fontSize:' + todoTitleSize + 'px'"
              v-html="todoTitle"
            ></span>
          </div>
          <div
            v-if="current.todo.howto"
            class="todo-howto"
            v-html="todoHowto"
          ></div>
          <canvas
            v-show="state === STATE.DL"
            id="myphoto"
            ref="myphoto"
            :width="size.width"
            :height="size.height"
            :style="
              'width:' + size.style.width + '; height:' + size.style.height
            "
          ></canvas>
        </div>
      </div>
      <!-- camera -->
      <div ref="container" key="container" class="camera">
        <div
          v-show="state === STATE.LOCATION || state === STATE.LOCATION_FOUND"
          id="rader"
          key="rader"
          class="rader"
        ></div>
        <video
          id="camera"
          ref="camera"
          key="camera"
          :class="state === STATE.SHOOT ? null : 'hidden'"
          playsinline
          muted
          :srcObject.prop="stream"
          :style="size.style"
          @canplay="canplayHandler"
          @loadeddata="loadeddata"
          @loadedmetadata="loadedmetadata"
          @ended="ended"
        ></video>
        <canvas
          v-show="state === STATE.SHOOT"
          id="frame"
          ref="frame"
          key="frame"
          :width="size.width"
          :height="size.height"
          :style="size.style"
        ></canvas>
      </div>
      <!-- debug info -->
      <div v-if="debug" class="info">
        <!-- motion 
        <div v-if="motion" class="motion">
          <div>
            <h2>motion.acceleration</h2>
            <template v-if="motion.acceleration">
              <p>x={{ motion.acceleration.x }}</p>
              <p>y={{ motion.acceleration.y }}</p>
              <p>z={{ motion.acceleration.z }}</p>
            </template>
          </div>
          <div>
            <h2>motion.accelerationIncludingGravity</h2>
            <template v-if="motion.accelerationIncludingGravity">
              <p>x={{ motion.accelerationIncludingGravity.x }}</p>
              <p>y={{ motion.accelerationIncludingGravity.y }}</p>
              <p>z={{ motion.accelerationIncludingGravity.z }}</p>
              <p>dev={{ angle }}</p>
            </template>
          </div>
          <div>
            <h2>motion.rotationRate</h2>
            <template v-if="motion.rotationRate">
              <p>α={{ motion.rotationRate.alpha }}</p>
              <p>β={{ motion.rotationRate.beta }}</p>
              <p>γ{{ motion.rotationRate.gamma }}</p>
            </template>
          </div>
          <div>
            <h2>motion.interval</h2>
            <p>{{ motion.interval }}</p>
          </div>
        </div>
        -->
        <!-- orientation
        <div v-if="orientation" class="orientation">
          <div>
            <h2>orientation</h2>
            <template v-if="orientation">
              <p>α={{ orientation.alpha }}</p>
              <p>β={{ orientation.beta }}</p>
              <p>γ={{ orientation.gamma }}</p>
              <p>a={{ orientation.absolute }}</p>
            </template>
          </div>
        </div>
        <div class="screen-orientation">
          <div>
            <h2>screen orientation</h2>
            <template v-if="orientation">
              <p>width={{ size.width }}</p>
              <p>height={{ size.height }}</p>
              <p>angle={{ screen_orientation }}</p>
              <p>isPortrait={{ isPortrait }}</p>
            </template>
          </div>
        </div>
        -->
        <div v-if="pos" class="geolocation">
          <div>
            <h2>geolocation</h2>
            <template v-if="pos">
              <p>lat={{ pos.latitude }}</p>
              <p>long={{ pos.longitude }}</p>
              <p>alt={{ pos.altitude }}</p>
              <p>acc={{ pos.accuracy }}</p>
              <p>altacc={{ pos.altitudeAccuracy }}</p>
              <p>heading={{ pos.heading }}</p>
              <p>speed={{ pos.speed }}</p>
              <p># target</p>
              <!--
              <p>lat={{ facility.lat }}</p>
              <p>lon={{ facility.lon }}</p>
              <p>range={{ facility.range }}</p>
              <p>dist={{ dist }}</p>
              <p>in={{ isInRange }}</p>
              <p># geodesy</p>
              -->
              <p>dist={{ dist }}</p>
              <p>bear={{ bear }}</p>
              <p>bear={{ direction }}</p>
              <p>in={{ inRange }}</p>
              <p>dif={{ dif }}</p>
            </template>
          </div>
        </div>
        <div v-if="supportedConstraints" class="supportedConstraints">
          <div>
            <h2>Supported Constraints</h2>
            <p v-for="(v, k) in supportedConstraints" :key="k">
              {{ k }}={{ v }}
            </p>
          </div>
        </div>
        <div class="log">
          <h2>ua</h2>
          <p>{{ ua }}</p>
          <h2>log</h2>
          <p v-for="(item, index) in log" :key="'log' + index">
            {{ item }}
          </p>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { getDistance, inRange } from '@/service/utils';
import LatLon from 'geodesy/latlon-ellipsoidal-vincenty.js';
function getBBox(scr, dst, hpos, vpos) {
  hpos = hpos ? hpos : 'center';
  vpos = vpos ? vpos : hpos;
  const box = {};
  const dst_rate = dst.w / dst.h;
  const scr_rate = scr.w / scr.h;
  if (dst_rate > scr_rate) {
    const height = (scr.h / dst_rate) * scr_rate;
    box.x = scr.x;
    box.y = scr.y + getOffset[vpos](scr.h, height);
    box.w = scr.w;
    box.h = height;
  } else {
    const width = (scr.w * dst_rate) / scr_rate;
    box.x = scr.x + getOffset[hpos](scr.w, width);
    box.y = scr.y;
    box.w = width;
    box.h = scr.h;
  }
  return box;
}

const getOffset = {
  top() {
    return 0;
  },
  left() {
    return 0;
  },
  center(scr, dst) {
    return (scr - dst) / 2;
  },
  bottom(scr, dst) {
    return scr - dst;
  },
  right(scr, dst) {
    return scr - dst;
  }
};

//const ANGLE_TO_RADIAN_RATE = Math.PI / 180;
const DIRECTION_STEP = 16;
const DIRECTION_UNIT = 360 / DIRECTION_STEP;
const DIRECTION_OFFSET = -DIRECTION_UNIT / 2;
const DIRECTION_MAP = [
  '北',
  '北北東',
  '北東',
  '東北東',
  '東',
  '東南東',
  '南東',
  '南南東',
  '南',
  '南南西',
  '南西',
  '西南西',
  '西',
  '西北西',
  '北西',
  '北北西'
];
function getDirection(bearing) {
  const id = Math.floor((bearing + DIRECTION_OFFSET) / DIRECTION_UNIT);
  return DIRECTION_MAP[id];
}

import adapter from 'webrtc-adapter';
const orientation =
  screen.msOrientation || screen.mozOrientation || screen.orientation;

import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: iconRetinaUrl,
  iconUrl: iconUrl,
  shadowUrl: shadowUrl
});

import { ENUM_STATE, STATES, _location_howto } from './landmark_resources.js';
import {
  formatMapper,
  formatAll,
  formatAllasLine,
  formatAsSpeechText
} from '@/service/formatter';

import back from '@/mixins/back';

export default {
  name: 'Landmark',
  components: {},
  mixins: [back],
  props: {
    title: String,
    id: String,
    no: Number,
    text: String,
    facility: Object
  },
  data() {
    return {
      ua: '',
      STATE: ENUM_STATE,
      debug_command_start: 0,
      debug_command_counter: 0,
      debug: false,
      log: [],
      state: 0,
      states: STATES,
      facingMode: 'environment',
      //lastUpdate: 0,
      //motion: null,
      //screen_orientation: 0,
      //orientation: null,
      pos: null,
      supportedConstraints: null,
      stream: null,
      size: {
        width: 1,
        height: 1,
        style: { top: 0, left: 0, width: 0, height: 0 }
      },
      videoSizeUpdateTimeout: 60 * 1000, //1min
      videoSizeUpdateStart: 0,
      videoSizeUpdateTimer: null,
      prevSize: {
        width: 1,
        height: 1
      },
      //dist: 0,
      //isInRange: false,
      angle: 0,
      dist: 0,
      bear: 0,
      direction: '',
      inRange: false,
      dif: 0,
      map_type: 'pale',
      map: null,
      group: null,
      markers: {
        circle: null,
        target: null,
        me: null
      },
      canSpeech: false,
      doSpeech: false,
      speech: null,
      todoTitleSize: 5
    };
  },
  computed: {
    range() {
      return this.facility.range;
    },
    current() {
      return this.states[this.state];
    },
    fTitle() {
      return formatAllasLine.format(this.title);
    },
    fText() {
      return formatAll.format(this.text);
    },
    sTitle() {
      return formatAsSpeechText.format(this.title);
    },
    sText() {
      return formatAsSpeechText.format(this.text);
    },
    todoTitle() {
      return formatMapper.format(this.current.todo.title, this);
    },
    todoHowto() {
      return formatMapper.format(this.current.todo.howto, this);
    },
    todoSpeech() {
      return (
        formatMapper.format(this.current.todo.speech_title, this) +
        '。' +
        formatMapper.format(this.current.todo.speech_howto, this)
      );
    }
  },
  watch: {
    todoTitle() {
      this.fitTodoTitle();
    }
  },
  async mounted() {
    if (this.id === undefined) {
      this.$router.push({ name: 'AbilityMenu' }).catch(() => {});
      return;
    }
    this.ua = window.navigator.userAgent;
    /* experimental feature
    window.addEventListener('devicemotion', this.motionHandler);
    */
    /* experimental feature
    window.addEventListener('deviceorientation', this.orientationHandler);
    */
    this.log.push('* init resize event');
    window.addEventListener('resize', this.resizeHandler);
    this.log.push('* init speechSynthesis');
    this.canSpeech = SpeechSynthesisUtterance && speechSynthesis;
    if (this.canSpeech) {
      this.speech = new SpeechSynthesisUtterance();
      this.speech.lang = 'ja-JP';
      this.speech.rate = 1.0;
      this.speech.pitch = 1.5;
      this.speech.volume = 1.0;
    }
    this.log.push('* check geolocation api');
    if (!(await this.checkGeolocationAPI())) return;

    this.log.push('* init geolocation api watcher');
    navigator.geolocation.watchPosition(
      this.geolocationHandler,
      err => {
        this.log.push(err);
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      }
    );

    this.log.push('* init camera api');
    await this.setMedia(this.STATE.LOCATION);
  },
  beforeDestroy() {
    /* experimental feature
    window.removeEventListener('devicemotion', this.motionHandler);
    */
    /* experimental feature
    window.removeEventListener('deviceorientation', this.orientationHandler);
    */
    /* clear geolocation api watcher*/
    navigator.geolocation.clearWatch(this.geolocation);
    /* remove resize event */
    window.removeEventListener('resize', this.resizeHandler);
    /* stop resize watcher */
    if (this.videoSizeUpdateTimer) {
      this.log.push('resize watcher off');
      clearInterval(this.videoSizeUpdateTimer);
      this.videoSizeUpdateTimer = null;
    }
  },
  methods: {
    resizeHandler() {
      this.fitTodoTitle();
      this.updateCanvas();
    },
    canplayHandler() {
      this.log.push('* canplay');
      this.updateCanvas();
    },
    loadedmetadata() {
      this.log.push('* loadedmetadata');
    },
    loadeddata() {
      this.log.push('* loadeddata');
    },
    ended() {
      this.log.push('* ended');
    },
    async fitTodoTitle() {
      this.todoTitleSize = 31;
      do {
        this.todoTitleSize--;
        await this.$nextTick();
        console.log(this.$refs.todoTitle.clientHeight, this.todoTitle);
      } while (
        this.todoTitleSize * 2.5 < this.$refs.todoTitle.clientHeight &&
        this.todoTitleSize > 10
      );
    },
    async switchCamera() {
      if (this.facingMode === '') {
        this.facingMode = 'environment';
      } else {
        this.facingMode = '';
      }
      const current = this.state;
      this.state = this.STATE.CAMERA_CHECK;
      this.log.push('* switch camera:');
      await this.setMedia(current);
    },
    setState(state) {
      this.state = state;
      this.exec_speech();
      if (this.state === this.STATE.SHOOT) {
        console.log('play');
        this.$refs.camera.play();
      } else {
        console.log('stop');
        this.$refs.camera.pause();
      }
      if (state === this.STATE.LOCATION && this.map === null) {
        this.$nextTick(() => {
          this.map = L.map('rader', {
            zoomControl: false
          }).setView([this.facility.lat, this.facility.lon], 18);
          L.tileLayer(
            'https://cyberjapandata.gsi.go.jp/xyz/' +
              this.map_type +
              '/{z}/{x}/{y}.png',
            {
              attribution:
                "<a href='https://maps.gsi.go.jp/development/ichiran.html' target='_blank'>地理院タイル</a>"
            }
          ).addTo(this.map);

          this.markers.circle = L.circle(
            [this.facility.lat, this.facility.lon],
            this.facility.range,
            {
              color: '#00c000',
              weight: 3,
              opacity: 0.8,
              fillColor: '#00c000',
              fillOpacity: 0.3
            }
          );
          this.group = L.featureGroup();
          this.markers.me = L.marker([this.facility.lat, this.facility.lon], {
            icon: L.icon({
              iconUrl: require('./img/landmark/blue.png'),
              iconSize: [30, 45], //200x298
              iconAnchor: [15, 45]
            })
          });
          this.markers.target = L.marker(
            [this.facility.lat, this.facility.lon],
            {
              icon: L.icon({
                iconUrl: require('./img/landmark/green.png'),
                iconSize: [30, 45], //200x298
                iconAnchor: [15, 45],
                tooltipAnchor: [0, 5],
                shadowUrl: require('./img/landmark/shadow.png'),
                shadowSize: [50, 36], //389x275
                shadowAnchor: [6, 30]
              })
            }
          ).bindTooltip(this.title, { permanent: true, direction: 'bottom' });
          this.group.addLayer(this.markers.circle);
          this.group.addLayer(this.markers.me);
          this.group.addLayer(this.markers.target);
          this.group.addTo(this.map);
        });
      }
    },
    debug_command() {
      const now = new Date().getTime();
      if (this.debug_command_start + 10000 < now) {
        this.debug_command_start = now;
        this.debug_command_counter = 0;
      }
      this.debug_command_counter++;
      if (this.debug_command_counter >= 10) {
        this.debug = true;
      }
    },
    /*
    motionHandler(evt) {
      this.motion = evt;
      this.angle =
        Math.ceil(this.motion.accelerationIncludingGravity.x / 9.80665) * 180 +
        Math.ceil(this.motion.accelerationIncludingGravity.y / 9.80665) * 90;
    },
    */
    /*
    orientationHandler(evt) {
      this.orientation = evt;
      if (this.geodesy && this.geodesy.bear) {
        let dif = this.geodesy.bear - this.orientation.alpha;
        dif = dif < 0 ? dif + 360 : dif > 360 ? dif - 360 : dif;
        this.geodesy.dif = dif;
      }
    },
    */
    checkGeolocationAPI() {
      return new Promise(resolve => {
        this.setState(this.STATE.GPS_CHECK);
        if (!navigator.geolocation) {
          this.log.push('  GeolocationAPI is not supported by your browser');
          this.setState(this.STATE.GPS_ERR);
          resolve(false);
        } else {
          navigator.geolocation.getCurrentPosition(
            pos => {
              //success
              this.log.push(
                '  GeolocationAPI succeeded to get location data: lat:' +
                  pos.coords.latitude +
                  ', lon:' +
                  pos.coords.longitude
              );
              this.pos = pos.coords;
              this.setState(this.STATE.CAMERA_CHECK);
              resolve(true);
            },
            err => {
              //error
              this.log.push(
                '  GeolocationAPI is not supported by your browser' +
                  err.toString()
              );
              this.setState(this.STATE.GPS_ERR);
              resolve(false);
            }
          );
        }
      });
    },
    geolocationHandler(pos) {
      this.pos = pos.coords;
      /* no use
      this.dist = getDistance(
        this.pos.latitude,
        this.pos.longitude,
        this.facility.lat,
        this.facility.lon
      );
      */
      /* no use
      this.isInRange = inRange(
        this.pos.latitude,
        this.pos.longitude,
        this.facility.lat,
        this.facility.lon,
        this.facility.range
      );
*/
      const target = new LatLon(this.facility.lat, this.facility.lon);
      const me = new LatLon(this.pos.latitude, this.pos.longitude);
      this.dist = me.distanceTo(target);
      this.bear = me.finalBearingTo(target);
      this.direction = getDirection(this.bear);
      /*
      const message = formatMapper.format(
        this.states[this.STATE.LOCATION].todo.howto,
        this.geodesy
      );
      const speech = formatMapper.format(
        this.states[this.STATE.LOCATION].todo.speech_howto,
        this.geodesy
      );
      this.states[this.STATE.LOCATION].todo.howto = message;
      this.states[this.STATE.LOCATION].todo.howto = message;
      this.states[this.STATE.LOCATION_FOUND].todo.howto = message;
      this.states[this.STATE.LOCATION_FOUND].todo.howto = message;
      */
      this.inRange = this.dist < this.facility.range;
      if (this.state === this.STATE.LOCATION && this.inRange) {
        this.setState(this.STATE.LOCATION_FOUND);
      }
      if (this.state === this.STATE.LOCATION_FOUND && !this.inRange) {
        this.setState(this.STATE.LOCATION);
      }

      if (this.map) {
        this.markers.me.setLatLng([this.pos.latitude, this.pos.longitude]);
        this.map.fitBounds(this.group.getBounds());
      }

      /* no use
      if (this.orientation && this.orientation.alpha) {
        let dif = this.angle + this.geodesy.bear - this.orientation.alpha;
        dif = dif < 0 ? dif + 360 : dif > 360 ? dif - 360 : dif;
        this.geodesy.dif = dif;
      }
      */
    },
    async setMedia(success_state) {
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }

      if (navigator.mediaDevices.getUserMedia === undefined) {
        this.log.push('  try getUserMedia fallback');
        navigator.mediaDevices.getUserMedia = function(constraints) {
          let getUserMedia =
            navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
          if (!getUserMedia) {
            this.log.push('  create Not Implemented Error');
            return Promise.reject(
              new Error('getUserMedia is not implemented in this browser')
            );
          }
          return new Promise(function(resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        };
      }

      this.supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
      try {
        this.stream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            width: { ideal: 5000 },
            height: { ideal: 5000 },
            facingMode: this.facingMode
          }
        });
        this.log.push('  succeeded to getUserMedia :');
        const tracks = this.stream.getVideoTracks();
        for (let track of tracks) {
          this.log.push('  contentHint:' + track.contentHint);
          this.log.push('  enabled:' + track.enabled);
          this.log.push('  readyState:' + track.readyState);
          const settings = track.getSettings();
          this.log.push('  settings:');
          for (let key in settings) {
            this.log.push('    ' + key + ':' + settings[key]);
          }
        }
        this.setState(success_state);
      } catch (e) {
        this.log.push('  error at getUserMedia :', e);
        this.setState(this.STATE.CAMERA_ERR);
      }
    },
    async updateCanvas() {
      await this.$nextTick();
      this.videoSizeUpdateStart = new Date().getTime();
      this.calcCanvasSize();
      if (!this.videoSizeUpdateTimer) {
        this.log.push('resize watcher on');
        this.videoSizeUpdateTimer = setInterval(() => {
          if (
            this.prevSize.width !== this.$refs.camera.videoWidth ||
            this.prevSize.height !== this.$refs.camera.videoHeight
          ) {
            this.calcCanvasSize();
          }
          if (
            this.videoSizeUpdateStart + this.videoSizeUpdateTimeout <
            new Date().getTime()
          ) {
            this.log.push('resize watcher off');
            clearInterval(this.videoSizeUpdateTimer);
            this.videoSizeUpdateTimer = null;
          }
        }, 500);
      }
    },
    calcCanvasSize() {
      if (
        this.$refs.camera.videoWidth > 0 &&
        this.$refs.camera.videoHeight > 0 &&
        this.$refs.container.clientHeight > 0
      ) {
        this.size.width = this.$refs.camera.videoWidth;
        this.size.height = this.$refs.camera.videoHeight;
        this.prevSize.width = this.size.width;
        this.prevSize.height = this.size.height;
        const rate = this.size.width / this.size.height;
        const clientRate =
          this.$refs.container.clientWidth / this.$refs.container.clientHeight;
        if (rate > clientRate) {
          const height = (100 / rate) * clientRate;
          this.size.style.top = (100 - height) / 2 + '%';
          this.size.style.left = 0;
          this.size.style.width = '100%';
          this.size.style.height = height + '%';
        } else {
          const width = (100 * rate) / clientRate;
          this.size.style.top = 0;
          this.size.style.left = (100 - width) / 2 + '%';
          this.size.style.width = width + '%';
          this.size.style.height = '100%';
        }
        this.log.push('vido size change detected');
        this.log.push(JSON.stringify(this.size, null, '  '));
        const ctx = this.getCtx('frame');
        ctx.clearRect(0, 0, this.size.width, this.size.height);
        this.drawFrames(ctx);
      }
    },
    getCtx(id) {
      const ctx = this.$refs[id].getContext('2d');
      ctx.mozImageSmoothingEnabled = true;
      ctx.webkitImageSmoothingEnabled = true;
      ctx.msImageSmoothingEnabled = true;
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = 'high';
      return ctx;
    },
    async drawFrames(ctx) {
      this.log.push('call drawFrames');
      const logo = new Image();
      logo.src = require('./img/landmark/logo.png');
      const scr = {
        x: this.size.width / 2,
        y: this.size.height / 2,
        w: this.size.width / 2,
        h: this.size.height / 2
      };

      await new Promise(resolve => {
        logo.onload = () => {
          const cli = {
            x: 0,
            y: 0,
            w: logo.naturalWidth,
            h: logo.naturalHeight
          };
          const box = getBBox(scr, cli, 'right', 'bottom');
          this.log.push(
            'draw logo',
            JSON.stringify(scr, null, '  '),
            JSON.stringify(cli, null, '  '),
            JSON.stringify(box, null, '  ')
          );
          ctx.drawImage(logo, box.x, box.y, box.w, box.h);
          ctx.fillStyle = '#fff';
          ctx.strokeStyle = '#000';
          const min = Math.min(this.size.width, this.size.height);
          const margin = min / 10;
          const date = new Date().toLocaleString('ja-u-ca-japanese', {
            year: '2-digit',
            month: 'narrow',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            weekday: 'narrow'
          });

          const fonts = [
            'メイリオ',
            'YuGothic',
            'YuGothicM',
            'Yu Gothic',
            'Yu Gothic Medium',
            '游ゴシック Medium',
            '游ゴシック体',
            'ヒラギノ角ゴ Pro W3',
            'sans-serif'
          ].join(',');
          let font_size = this.size.width / 30;
          const title_width = this.size.width * 0.8;
          let vmargin = margin;
          for (; font_size > 0; font_size--) {
            ctx.font = '700 ' + font_size + 'px ' + fonts;
            const date_measure = ctx.measureText(date);
            ctx.font = '700 ' + font_size * 3 + 'px ' + fonts;
            const name_measure = ctx.measureText(this.facility.name);
            if (
              date_measure.width < title_width &&
              name_measure.width < title_width
            ) {
              vmargin +=
                (date_measure.actualBoundingBoxAscent +
                  date_measure.actualBoundingBoxDescent) *
                  0.7 +
                name_measure.actualBoundingBoxAscent;
              break;
            }
          }
          console.log(font_size);
          ctx.font = '700 ' + font_size + 'px ' + fonts;
          ctx.strokeText(date, margin, margin);
          ctx.fillText(date, margin, margin);
          ctx.font = '700 ' + font_size * 3 + 'px ' + fonts;
          ctx.strokeText(this.facility.name, margin, vmargin);
          ctx.fillText(this.facility.name, margin, vmargin);
          resolve();
        };
      });
    },
    async shoot() {
      this.$tryLoadingAsync(async () => {
        const ctx = this.getCtx('myphoto');
        ctx.drawImage(this.$refs.camera, 0, 0);
        await this.drawFrames(ctx);
        this.$refs.dl.href = this.$refs.myphoto.toDataURL('image/png');
        this.$refs.dl.download = 'mican-' + new Date().toISOString() + '.png';
        this.setState(this.STATE.DL);
        /* send completed flag for AR*/
        const payload = {
          id: this.id,
          no: this.no
        };
        await this.$store.dispatch('api/setFacilityUserData', payload);
      });
    },
    dl(evt) {
      if (!this.STATE.DL) {
        evt.preventDefault();
      }
    },
    exec_speech() {
      if (this.doSpeech && this.canSpeech) {
        const todo = this.states[this.state].todo;
        this.speech.text = this.todoSpeech;
        speechSynthesis.speak(this.speech);
      }
    },
    toggleSpeech() {
      this.doSpeech = !this.doSpeech;
      this.exec_speech();
    }
  }
};
</script>

<style scoped>
.landmark {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: linear-gradient(
    -45deg,
    #fdf5cf 25%,
    #fff0b3 25%,
    #fff0b3 50%,
    #fdf5cf 50%,
    #fdf5cf 75%,
    #fff0b3 75%,
    #fff0b3
  );
  background-size: 18px 18px;
  background-repeat: repeat;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  font-size: 2vw;
}

.bottom-console {
  position: fixed;
  left: 0%;
  bottom: 0%;
  width: 100%;
  z-index: 100;
}
.bottom-console > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.console,
main {
  position: relative;
}

.console {
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  pointer-events: none;
  position: absolute;
  width: 80%;
  left: 10%;
}

.current-state,
.console a,
.bottom-console a {
  display: block;
  color: #fff;
  font-weight: bold;
  margin: 0 1em 1em 1em;
  padding: 0.5em 1em;
  background-color: #fb861f;
  border-radius: 2em;
  pointer-events: all;
  white-space: nowrap;
  height: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 2em !important;
}
.console a:hover,
.bottom-console a:hover {
  background-color: #ffb257;
}
main {
  flex: 1;
  z-index: 50;
}
main > * {
  position: absolute;
}

.todo {
  top: 70px;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 100;
  padding: 1em;
  height: calc(100% - 70px);
  overflow: hidden;
  max-width: 100%;
}

.todo img {
  width: auto;
  height: 5em;
  object-fit: contain;
  z-index: 102;
  position: relative;
}

.todo .todo-message {
  background-color: #fff;
  border-radius: 1em;
  margin-left: 1em;
  padding: 1em;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100%;
  z-index: 101;
  opacity: 0.9;
  position: relative;
}
.todo .todo-title {
  font-size: 1.5em;
  font-weight: bold;
  line-height: 2em;
}
.todo .todo-title span {
  line-height: 2em;
  display: block;
}
.todo .todo-howto {
  margin-top: 0.5em;
  line-height: 1.5;
}

.todo.dl {
}

.rader {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.camera {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 10;
  pointer-events: none;
}

#camera {
  position: absolute;
  object-fit: contain;
  border: none;
  z-index: 1;
}
#camera.hidden {
  visibility: hidden;
}

#frame {
  z-index: 2;
}
.frame {
  position: absolute;
  object-fit: contain;
  visibility: hidden;
}
.info {
  position: absolute;
  top: 50%;
  left: 0;
  font-size: 50%;
  width: 50%;
  height: 50%;
  background-color: #fff8;
  color: #000;
  z-index: 101;
  overflow: auto;
  font-family: monospace;
}

.info h2 {
}
.info p {
  margin-left: 1em;
  white-space: pre;
}
.info > div {
  padding: 1em;
}

/*戻るボタン 読み上げボタン レイアウト*/
/*メニュー*/
.btn-back {
  left: 10px;
  top: 10px;
  z-index: 90;
}
.btn-auto-read {
  right: 10px;
  top: 10px;
  z-index: 90;
}
@media only screen and (max-width: 771px) {
  .btn-auto-read {
    right: 2%;
    top: 10px;
  }
}

@media screen and (max-aspect-ratio: 1/1) {
  .info {
    font-size: 100%;
    width: 100%;
  }
}
@media screen and (max-aspect-ratio: 2/3) {
  .landmark {
    font-size: 3vw;
  }
  .todo {
    display: block;
    padding: 0;
    width: 100%;
  }
  .todo img {
    margin: 0.25em 0.25em -4em 0.25em;
  }
  .todo .todo-title {
    margin-left: 3.5em;
  }
  .todo .todo-message {
    border-radius: 0;
    margin: 0;
    padding: 1em;
    min-height: 5.5em;
  }
}
@media screen and (max-aspect-ratio: 9/16) {
  .landmark {
    font-size: 4vw;
  }
  .bottom-console > div {
    flex-direction: column;
  }
}

::-webkit-scrollbar {
  width: 1em;
  height: 1em;
}
::-webkit-scrollbar-thumb {
  background: #444;
  border-radius: 0.5em;
  border: 0.25em solid #fff;
}
::-webkit-scrollbar-track {
  background: #eee;
  border-radius: 1em;
  border: 0.25em solid #fff;
}
::-webkit-scrollbar-thumb:hover {
  background: #444;
}
</style>
