import {
  formatAll,
  formatAllasLine,
  formatAsSpeechText,
  formatSpeechMapperTag
} from '@/service/formatter';
export const ENUM_STATE = {
  LOADING: 0,
  GPS_CHECK: 1,
  GPS_ERR: 2,
  CAMERA_CHECK: 3,
  CAMERA_ERR: 4,
  LOCATION: 5,
  LOCATION_FOUND: 6,
  SHOOT: 7,
  DL: 8
};

const DO_NOT_MODIFY_COZ_DATA_IS_SET_FROM_CODE = null;

export const STATES = [
  {
    id: 'loading',
    name: 'LOADING',
    todo: {
      title: '',
      image: '',
      howto: ''
    }
  },
  {
    id: 'gps',
    name: 'GPS',
    todo: {
      title: 'いまおるとこ[探:さが]しとるけん、まがられんよ。',
      image: require('./img/landmark/GPS.png'),
      howto: ''
    }
  },
  {
    id: 'gps-err',
    name: 'GPS Error',
    todo: {
      title: 'おまん、どこにおるんぞや？',
      image: require('./img/landmark/GPS_NG.png'),
      howto:
        'GPSが[検出:けんしゅつ]できません。\n' +
        '1.デバイスにGPS[機能:きのう]がついているか[確認:かくにん]してください。\n' +
        '2.ブラウザにGPSが[許可:きょか]されているか[確認:かくにん]してください。\n' +
        '3.デバイスのGPSがオンになっているか[確認:かくにん]してください。'
    }
  },
  {
    id: 'camera',
    name: 'Camera',
    todo: {
      title: 'いまカメラつけよるけん',
      image: require('./img/landmark/CAMERA.png'),
      howto: ''
    }
  },
  {
    id: 'camera-err',
    name: 'Camera Error',
    todo: {
      title: 'どーもこーもならん',
      image: require('./img/landmark/CAMERA_NG.png'),
      howto:
        'カメラが[検出:けんしゅつ]できません。\n' +
        '1.デバイスにカメラがついているか[確認:かくにん]してください。\n' +
        '2.ブラウザにカメラが[許可:きょか]されているか[確認:かくにん]してください。'
    }
  },
  {
    id: 'location',
    name: 'Location',
    todo: {
      title: 'もっとちこうおいでなもし',
      image: require('./img/landmark/LOCATION.png'),
      howto:
        '[目標:もくひょう]の[半径:はんけい]{{range}}m[以内:いない]に入ってください。\n' +
        '[目標:もくひょう]まで{{direction}}に{{dist}}m[離:はな]れています。'
    }
  },
  {
    id: 'location-found',
    name: 'Location Found',
    todo: {
      title: 'ようおいでたなもし',
      image: require('./img/landmark/LOCATION.png'),
      howto: 'ボタンを[押:お]してカメラを[起動:きどう]してください。'
    }
  },
  {
    id: 'shoot',
    name: 'Shoot',
    todo: {
      title: '[好:す]きなとこでお[撮:と]りや',
      image: require('./img/landmark/SHOOT.png'),
      howto:
        '[好:す]きな[場所:ばしょ]を[選:えら]んで[撮影:さつえい]ボタンを[押:お]してください。'
    }
  },
  {
    id: 'dl',
    name: 'Download',
    todo: {
      title: '{{fTitle}}とは',
      image: require('./img/landmark/SHOOT.png'),
      howto: '{{fText}}'
    }
  }
];

console.log('init landmark res');
for (let state of STATES) {
  state.todo.speech_title = formatSpeechMapperTag.format(
    formatAsSpeechText.format(state.todo.title)
  );
  state.todo.speech_howto = formatSpeechMapperTag.format(
    formatAsSpeechText.format(state.todo.howto)
  );
  state.todo.title = formatAllasLine.format(state.todo.title);
  state.todo.howto = formatAll.format(state.todo.howto);
}
